import React from 'react';
import { InfoHeader } from '../header';
import { styled } from '@hiyllo/ux/styled';
import { Typography } from '@hiyllo/ux/typography';
import { GradientText } from '../../landing';
import { faAt, faBlock, faBoothCurtain, faBroadcastTower, faBrowser, faCalendar, faComment, faCrateEmpty, faCrystalBall, faDiagramNested, faFolder, faFont, faGraduationCap, faHashtag, faListCheck, faPaperclip, faReply, faRocket, faRunning, faSearch, faSmileWink, faSolarSystem, faSortSizeDown, faSparkles, faSquareKanban, faThumbTack, faUnlock, faUpDown, faUser, faUsers, faZ } from '@fortawesome/pro-light-svg-icons';
import { ChatThreadIcon, NymblIcon, ScreenshareIcon, VideoMeetingIcon } from '@hiyllo/icons/main';
import { FeatureInfoCard, FeaturePill } from '../components';
import { Footer } from '../footer';
import { FakeChatWindow } from '../../fake-chat-window';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@hiyllo/ux/button';
import { useTheme } from '@hiyllo/ux/theme';
import { faSlack, faTrello } from '@fortawesome/free-brands-svg-icons';

const Card = styled('div', ({ $theme }) => ({
    background: $theme.background2,
    padding: 20,
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 10,
    boxShadow: 'rgba(0,0,0,0.75) 0px 0px 50px -5px',
    textAlign: 'center'
}));

const Container = styled('div', ({ $theme }) => ({
    color: $theme.foreground
}));

const JumboText = styled('div', ({ $theme }) => ({
  fontSize: 100
}));

const TOTAL = 8.75 + 12.5 + 12 + 12 + 15.99;

export const PricingPage = React.memo(function PricingPage(): JSX.Element {
    const $theme = useTheme();
    return (
        <Container>
            <InfoHeader/>
            <div style={{ height: 90 }}/>
            <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 0, display: 'flex', flexDirection: 'row', gap: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <JumboText style={{ fontSize: 40 }}>
                        Pricing
                    </JumboText>
                    <div style={{ height: 20 }}/>
                    <Card style={{ width: 'min(300px, 65vw)', userSelect: 'none' }}>
                        <JumboText>
                            <GradientText style={{ letterSpacing: 1, textAlign: 'center' }}>
                                $19.99
                            </GradientText>
                        </JumboText>
                        <JumboText style={{ fontSize: 20 }}>per user / month</JumboText>
                    </Card>
                    <div style={{ height: 20 }}/>
                    <div style={{ fontSize: 15, userSelect: 'none', lineHeight: '17.5px', maxWidth: 400, display: 'flex', flexDirection: 'column', gap: 10, textAlign: 'left' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                            <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faSolarSystem} fixedWidth/></div>
                            <div>One plan, all the features in the universe (and beyond!)</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                            <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faCalendar} fixedWidth/></div>
                            <div>Billed monthly, no annual contract or lock-in, no minimums</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                            <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faFolder} fixedWidth/></div>
                            <div>You get 25GB of pooled storage with every user. Need more? We keep it simple: just 5&cent;/gb/mo</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                            <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faUnlock} fixedWidth/></div>
                            <div>Get 3 weeks free: no credit card needed, no commitment, no strings attached.</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                            <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faRocket} fixedWidth/></div>
                            <div>Startups and non-profits are eligible for discounts of up to 25%, email <span style={{ userSelect: 'text' }}>sales@hiyllo.com</span> for more information.</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                            <div style={{ flexShrink: 0 }}><FontAwesomeIcon icon={faGraduationCap} fixedWidth/></div>
                            <div>Students get Hiyllo Work for free for any purpose, just sign up with a <b>.edu</b> email or contact <span style={{ userSelect: 'text' }}>students@hiyllo.com</span></div>
                        </div>
                    </div>
                </div>
                <div style={{
                    background: $theme.background2,
                    borderRadius: 10,
                    padding: 20,
                    flexGrow: 1
                }}>
                    <Typography.Header>Compare Hiyllo Work</Typography.Header>
                    <div style={{ marginTop: 5, display: 'flex', flexDirection: 'column', gap: 5 }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 10
                            }}
                        >
                            {/* @ts-expect-error --- */}
                            <FontAwesomeIcon icon={faSlack} fixedWidth/>
                            <div>Slack Pro - $8.75/user/mo (billed monthly)</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 10
                            }}
                        >
                            {/* @ts-expect-error --- */}
                            <FontAwesomeIcon icon={faTrello} fixedWidth/>
                            <div>Trello Premium - $12.50/user/mo (billed monthly)</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 10
                            }}
                        >
                            <FontAwesomeIcon icon={faCalendar} fixedWidth/>
                            <div>Calendly Standard - $12/user/mo (billed monthly)</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 10
                            }}
                        >
                            <FontAwesomeIcon icon={faBlock} fixedWidth/>
                            <div>Notion Plus - $12/user/mo (billed monthly)</div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 10
                            }}
                        >
                            <FontAwesomeIcon icon={faZ} fixedWidth/>
                            <div>Zoom Pro - $15.99/user/mo (billed monthly)</div>
                        </div>
                        <div style={{ height: 0.51, width: 500, marginTop: 5, marginBottom: 2.5, backgroundColor: 'white' }}/>
                        <div style={{ fontSize: 17.5, display: 'flex', flexDirection: 'column', gap: 5 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 10
                                }}
                            >
                                <div>
                                    <b>Total: ${TOTAL} / user / month</b>
                                </div>
                            </div>
                            <div>
                                <b>Saving per user per month: ${TOTAL - 19.99}</b>
                            </div>
                            <div>
                                <b>10 person company savings per month: ${((TOTAL - 19.99) * 10).toFixed(2)}</b>
                            </div>
                            <div>
                                <b>10 person company savings per year: ${((TOTAL - 19.99) * 10 * 12).toFixed(2)}</b>
                            </div>
                        </div>
                        <div style={{ fontSize: 10, paddingTop: 20 }}>
                            All trademarks belong to respective owners. Prices as of December 20th, 2024; not including any introductory discounts.
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </Container>
    );
});
