import React from 'react';
import { Input, useInputControl } from '@hiyllo/ux/input';
import { Typography } from '@hiyllo/ux/typography';
import { Structure } from '@hiyllo/ux/structure';
import { CheckboxInput } from '@hiyllo/ux/checkbox-input';
import { Button } from '@hiyllo/ux/button';

import * as RegisterBP from '../../../blueprints/partners/register';
import { seamlessClient } from '../../../seamless';
import { useShowAlert } from '@hiyllo/ux/dialogs';
import { MoopsyError } from '@moopsyjs/core/main';
import { UseMoopsyQueryRetValAny } from '@moopsyjs/react/main';

export const PartnerSignUpForm = React.memo(function PartnerSignUpForm(props: {
    registrationsQuery: UseMoopsyQueryRetValAny;
}): JSX.Element {
    const registerMutation = seamlessClient.useMutation<RegisterBP.Plug>(RegisterBP, { querySideEffects: [props.registrationsQuery] });
    const legalNameIC = useInputControl({});
    const [termsAgreed, setTermsAgreed] = React.useState(false);
    const showAlert = useShowAlert();

    const completeSignup = React.useCallback(() => {
        if (!termsAgreed) return;

        void registerMutation.call({
            legalName: legalNameIC.valueRef.current
        }).then(() => {
            //
        }).catch((err) => {
            void showAlert({
                title: 'Unable to register',
                message: (err as MoopsyError).message
            });
        });
    }, [legalNameIC.valueRef, registerMutation, showAlert, termsAgreed]);

    return (
        <div>
            <Typography.SubHeader>Step 2: Complete signup</Typography.SubHeader>
            <Typography.Label>Beneficiary Legal Name (For individuals enter your name, for corporate entities enter your corporate entity&apos;s name)</Typography.Label>
            <Input
                placeholder='Taylor Doe / Acme Inc'
                {...legalNameIC.inputProps}
            />
            <div style={{ height: 10 }}/>
            <Structure.Row>
                <CheckboxInput
                    label="I agree to the Referral Program Terms above"
                    onChange={setTermsAgreed}
                    value={termsAgreed}
                />
                <Button
                    label="Sign Up"
                    onClick={completeSignup}
                    isSecondary={!termsAgreed}
                    isLoading={registerMutation.isLoading}
                />
            </Structure.Row>
        </div>
    );
});
