/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
    publicKey: {
        pem: string;
    }
};
export type ResponseType = {
    apiKeyId: string;
};
export const Endpoint = 'api/create-api-key';
export const Method = 'POST';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"publicKey":{"type":"object","properties":{"pem":{"type":"string"}},"required":["pem"]}},"required":["publicKey"],"$schema":"http://json-schema.org/draft-07/schema#"}