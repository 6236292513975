import React from 'react';
import { InlineLink } from '@hiyllo/ux/inline-link';

const IS_MOBILE = window.innerHeight > window.innerWidth;

export const Footer = React.memo(function Footer(): JSX.Element {
  return (
    <div style={{ textAlign: 'center', padding: 20, fontSize: 12 }}>
      <a href="https://www.hiyllo.com">
        <div>
          <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-white.png" style={{ height: 20, width: 20, opacity: 0.75, paddingBottom: 10 }}/>
        </div>
      </a>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 20, justifyContent: 'center', alignItems: 'center', whiteSpace: 'nowrap' }}>
        {IS_MOBILE ?
          <div>&copy; Hiyllo Inc 2025</div>
        :
          <div>&copy; Hiyllo Inc 2025, All Rights Reserved</div>
        }
        <InlineLink href='/privacy-policy' label='Privacy Policy' />
        <InlineLink href='mailto:support@hiyllo.com' label='support@hiyllo.com' />
      </div>
    </div>
  );
});
