import React from 'react';
import { Typography } from '@hiyllo/ux/typography';

import * as GetMyRegistrationsBP from '../../../blueprints/partners/get-my-registrations';
import { seamlessClient } from '../../../seamless';
import { LoadingSpinnerView } from '@hiyllo/ux/loading-spinner';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import { faExclamationTriangle, faLaptop } from '@fortawesome/pro-light-svg-icons';
import { PartnerProgramRegistration } from '../../../types/partners';
import { InlineLink } from '@hiyllo/ux/inline-link';
import { Input } from '@hiyllo/ux/input';
import { PartnerSignUpForm } from './sign-up-form';
import { Table, TableCell, TableRow } from '@hiyllo/ux/table';
import { Structure } from '@hiyllo/ux/structure';
import { Card } from '@hiyllo/ux/surface';

const DEFAULT_COMPENSATION_MATRIX = {
    year1: { e2: 2000 },
    year2: { e2: 1750 },
    year3: { e2: 1500 },
    year4: { e2: 1000 },
    year5: { e2: 500 },
    onwards: { e2: 100 }
  };

export const PartnersView = React.memo(function PartnersView(): JSX.Element {
    const registrationsQuery = seamlessClient.useQuery<GetMyRegistrationsBP.Plug>(GetMyRegistrationsBP, null);

    if (registrationsQuery.isLoading) {
        return <LoadingSpinnerView/>;
    }

    if (registrationsQuery.isError) {
        return (
            <EmptySplash
                icon={faExclamationTriangle}
                label='Error'
                hint={registrationsQuery.error.message}
            />
        );
    }

    const registration: PartnerProgramRegistration | null = registrationsQuery.data.registrations[0] ?? null;

    if (window.innerHeight > window.innerWidth) {
        return (
            <EmptySplash
                icon={faLaptop}
                label='Return here on your laptop'
                hint={'The Hiyllo Partner Program is only available on desktop. Please return here on your laptop.'}
            />
        );
    }

    if (registration == null) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 25 }}>
                <div>
                    <Typography.Header>Hiyllo Parner Program</Typography.Header>
                    <Typography.Paragraph>
                        Refer organizations to Hiyllo, and earn a percentage of their subscription
                    </Typography.Paragraph>
                    <div style={{ height: 20 }}/>
                    <Typography.SubHeader>Step 1: Review the program terms</Typography.SubHeader>
                    <div>
                        <InlineLink
                            href='/referral-terms'
                            label='Referral Program Terms'
                        />
                    </div>
                    <div style={{ height: 20 }}/>
                    <PartnerSignUpForm registrationsQuery={registrationsQuery}/>
                </div>
                <div style={{ width: 300 }}>
                    <Typography.SubHeader>Revenue Share Plan</Typography.SubHeader>
                    <Typography.Paragraph>Subject to change with notice. See Referral Program Terms.</Typography.Paragraph>
                    <div style={{ height: 5 }}/>
                    <Table header={['Year', 'Revenue Percentage You Earn']}>
                        <TableRow>
                            <TableCell>Year 1</TableCell>
                            <TableCell>
                                {(DEFAULT_COMPENSATION_MATRIX.year1.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Year 2</TableCell>
                            <TableCell>
                                {(DEFAULT_COMPENSATION_MATRIX.year2.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Year 3</TableCell>
                            <TableCell>
                                {(DEFAULT_COMPENSATION_MATRIX.year3.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Year 4</TableCell>
                            <TableCell>
                                {(DEFAULT_COMPENSATION_MATRIX.year4.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Year 5</TableCell>
                            <TableCell>
                                {(DEFAULT_COMPENSATION_MATRIX.year5.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Year 6+</TableCell>
                            <TableCell>
                                {(DEFAULT_COMPENSATION_MATRIX.onwards.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                    </Table>
                    <div style={{ height: 5 }}/>
                    <Typography.Paragraph>&quot;Year&quot; refers to the year of each individual subscription created with your referral</Typography.Paragraph>
                </div>
            </div>
        );
    }

    return (
        <Structure.LColumn10>
            <Typography.Header>Parner Program</Typography.Header>
            <div>
                Your referral link: <InlineLink href={`https://www.hiyllo.work?hyref=${registration.referralTokens[0]}`} label={`https://www.hiyllo.work?hyref=${registration.referralTokens[0]}`}/>. To request a custom link email <InlineLink href="mailto:partners@hiyllo.com" label='partners@hiyllo.com'/>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'stretch' }}>
                <div style={{ width: 300 }}>
                    <Table header={['Year', 'Compensation Percentage']}>
                        <TableRow>
                            <TableCell>Year 1</TableCell>
                            <TableCell>
                                {(registration.compensation.year1.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Year 2</TableCell>
                            <TableCell>
                                {(registration.compensation.year2.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Year 3</TableCell>
                            <TableCell>
                                {(registration.compensation.year3.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Year 4</TableCell>
                            <TableCell>
                                {(registration.compensation.year4.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Year 5</TableCell>
                            <TableCell>
                                {(registration.compensation.year5.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Year 6+</TableCell>
                            <TableCell>
                                {(registration.compensation.onwards.e2 / 100).toFixed(2)}%
                            </TableCell>
                        </TableRow>
                    </Table>
                </div>
                <Card color="background2">
                    <div>Hiyllo Points</div>
                    <div style={{ fontSize: 100, lineHeight: '1em', fontFamily: '"Space Mono", monospace', fontWeight: 'bold' }}>0</div>
                    <div style={{ height: 10 }}/>
                    <div>Pending Hiyllo Points</div>
                    <div style={{ fontSize: 50, lineHeight: '1em', fontFamily: '"Space Mono", monospace', fontWeight: 'bold' }}>0</div>
                    <div style={{ height: 10 }}/>
                    <div>1 Hiyllo Point = 1&cent; USD</div>
                </Card>
            </div>
        </Structure.LColumn10>
    );
});
