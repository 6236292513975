export async function generateAndExportRSAKey (): Promise<{ publicKeyPem: string, privateKeyPem: string }> {
  // Generate the RSA key pair
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: 'RSA-OAEP', // Algorithm name for encryption
      modulusLength: 4096, // Key size in bits
      publicExponent: new Uint8Array([1, 0, 1]), // 65537 as a byte array
      hash: { name: 'SHA-256' } // Hash function to use with RSA-OAEP
    },
    true, // Make the key extractable
    ['encrypt', 'decrypt'] // Key usages (adjust for signing if needed)
  );

  const publicKeyPem = convertSpkiToPem(
    'PUBLIC',
    await window.crypto.subtle.exportKey('spki', keyPair.publicKey)
  );

  const privateKeyPem = convertSpkiToPem(
    'PRIVATE',
    await window.crypto.subtle.exportKey('pkcs8', keyPair.privateKey)
  );

  return {
    publicKeyPem,
    privateKeyPem
  };
}

/**
   * Converts an ArrayBuffer (from SPKI export) to a PEM-formatted string.
   * @param {ArrayBuffer} spkiBuffer - The ArrayBuffer containing the SPKI key data.
   * @returns {string} PEM formatted public key.
   */
function convertSpkiToPem (type: 'PUBLIC' | 'PRIVATE', spkiBuffer: ArrayBuffer): string {
  // Convert the ArrayBuffer to a Base64 encoded string
  const base64String = arrayBufferToBase64(spkiBuffer);
  const m = base64String.match(/.{1,64}/g);

  if (m == null) {
    throw new Error('Failed to convert SPKI buffer to Base64 string');
  }

  // Insert line breaks every 64 characters for PEM formatting
  const formattedKey = m.join('\n');
  // Wrap with PEM header and footer
  return `-----BEGIN ${type} KEY-----\n${formattedKey}\n-----END ${type} KEY-----`;
}

/**
   * Converts an ArrayBuffer to a Base64 encoded string.
   * @param {ArrayBuffer} buffer - The ArrayBuffer to convert.
   * @returns {string} Base64 encoded string.
   */
function arrayBufferToBase64 (buffer: ArrayBuffer): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (const byte of bytes) {
    binary += String.fromCharCode(byte);
  }
  return window.btoa(binary);
}
