/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type ParamsType = {
    productUUID: string;
};
export type ResponseType = void;
export const Endpoint = 'products/reinit';
export const Method = 'POST';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"productUUID":{"type":"string"}},"required":["productUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}