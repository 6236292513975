/* eslint-disable no-useless-call */
import React from 'react';
import { seamlessAuth } from './seamless';
import { MoopsyClientAuthExtensionState } from '@moopsyjs/react';
import { LoggedOutRouter, Router } from './src/router';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTheme } from '@hiyllo/ux/theme';
import { DialogProvider } from '@hiyllo/ux/dialogs';
import { WebPortalsProvider } from '@hiyllo/ux/web-portals';
import { FiberProvider } from 'its-fine';
import { LoadingPage } from '@hiyllo/ux/standard-loader';

export const useAuthSessionToken = (): string | null => {
  return window.sessionStorage.token ?? null;
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(window.location.hostname.includes('localhost') ? 'pk_test_51LmpU9C8hWRqxVWADDT8ylzXYTgHrU1D0ZtZ2SpFqVzh3TGzQwMeD8znuQoqSUHpY52YFQ3CFGHAb2H7FfmCxjEz00ZMf1QcBv' : 'pk_live_51LmpU9C8hWRqxVWAVILhlntvsTUDazuJGmolnH5oZNqZ42U24zYXtgARgiaJVQQhxT0HVslNvXYcG0Y6jFpcKzYd00ctSJ0tZy');

const usp = new URLSearchParams(window.location.search);
if (usp.get('hyref') != null) {
  window.localStorage.hyref = usp.get('hyref');
}

export const App = (): React.ReactElement => {
  const authStatus = seamlessAuth.useAuthStatus();
  const authSessionToken = useAuthSessionToken();
  const theme = useTheme();

  if (authStatus === MoopsyClientAuthExtensionState.loggedIn) {
    return (
      <Elements
        stripe={stripePromise} options={{
          paymentMethodCreation: 'manual',
          mode: 'setup',
          currency: 'usd',
          appearance: {
            theme: 'night',
            variables: { colorBackground: theme.midground },
            rules: {
              '.Input': {
                boxShadow: 'none',
                border: 'none',
                borderRadius: '10px',
                fontSize: '14px'
              }
            }
          }
        }}
      >
        <div style={{ minHeight: '100vh' }}>
          <FiberProvider>
            <WebPortalsProvider>
              <DialogProvider>
                <Router/>
              </DialogProvider>
            </WebPortalsProvider>
          </FiberProvider>
        </div>
      </Elements>
    );
  }

  if (authSessionToken !== null) {
    return (
      <LoadingPage/>
    );
  }

  return (
    <LoggedOutRouter/>
  );
};
