/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { SubscriptionModificationType } from "../../types/subscription";

export type ParamsType = {
    subscriptionUUID: string;
    modification: Omit<SubscriptionModificationType, "createdBy">;
};
export type ResponseType = void;
export const Endpoint = 'subscriptions/apply-modification';
export const Method = 'POST';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"subscriptionUUID":{"type":"string"},"modification":{"$ref":"#/definitions/Omit<SubscriptionModificationType,\"createdBy\">"}},"required":["modification","subscriptionUUID"],"definitions":{"Omit<SubscriptionModificationType,\"createdBy\">":{"type":"object","properties":{"value":{"anyOf":[{"$ref":"#/definitions/SubModUserCountAdjustment"},{"$ref":"#/definitions/SubModBillingRateAdjustment"}]},"note":{"type":"string"}},"required":["note","value"]},"SubModUserCountAdjustment":{"type":"object","properties":{"kind":{"type":"string","enum":["user-count-adjustment"]},"adjustment":{"type":"number"}},"required":["adjustment","kind"]},"SubModBillingRateAdjustment":{"type":"object","properties":{"kind":{"type":"string","enum":["billing-rate-adjustment"]},"baseCostE2":{"type":["null","number"]}},"required":["baseCostE2","kind"]}},"$schema":"http://json-schema.org/draft-07/schema#"}