/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
/**
 * [Hiyllo Business] Gets details about a given product
 */

import { Invoice } from "../../types/invoices";
import { Product } from "../../types/product";
import { SubscriptionType } from "../../types/subscription";

export type ParamsType = {
    productUUID: string;
};
export type ResponseType = {
    product: Product;
    subscription: SubscriptionType;
    federation: {
        name: string;
        slug: string;
        superadmins: string[];
    }
    recentInvoices: Invoice[];
};
export const Endpoint = 'products/get-product';
export const Method = 'GET';

export interface Plug {
    params: ParamsType
    response: ResponseType
    method: typeof Method
    endpoint: typeof Endpoint
};export const paramsSchema = {"type":"object","properties":{"productUUID":{"type":"string"}},"required":["productUUID"],"$schema":"http://json-schema.org/draft-07/schema#"}