import React from 'react';

import * as ListAPIKeysBP from '../../../blueprints/api/list-api-keys';
import * as CreateAPIKeyBP from '../../../blueprints/api/create-api-key';

import { seamlessClient } from '../../../seamless';
import { LoadingSpinnerView } from '@hiyllo/ux/loading-spinner';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { Table, TableCell, TableRow } from '@hiyllo/ux/table';
import { Button, ButtonVariant } from '@hiyllo/ux/button';
import { generateAndExportRSAKey } from './lib/generate-key';
import { Typography } from '@hiyllo/ux/typography';
import { InlineLink } from '@hiyllo/ux/inline-link';

export const APIView = React.memo(function APIView(): JSX.Element {
    const listApiKeysQuery = seamlessClient.useQuery<ListAPIKeysBP.Plug>(ListAPIKeysBP, {});
    const createApiKeyMutation = seamlessClient.useMutation<CreateAPIKeyBP.Plug>(CreateAPIKeyBP);
    const [key, setKey] = React.useState<string | null>(null);
    const [apiKeyId, setApiKeyId] = React.useState<string | null>(null);

    const createAPIKey = React.useCallback(() => {
        void generateAndExportRSAKey().then(keys => {
            void createApiKeyMutation.call({
                publicKey: {
                    pem: keys.publicKeyPem
                }
            }).then(res => {
                setApiKeyId(res.apiKeyId);
                setKey(`@hiyllo/api;private:${window.btoa(keys.privateKeyPem)}::${res.apiKeyId}`);
            });
        });
    }, [createApiKeyMutation]);

    const downloadKey = React.useCallback(() => {
        if (key == null) {
            return;
        }

        const link = document.createElement('a');
        link.href = `data:text/plain;charset=utf-8,${key}`;
        link.download = `hiyllo-private-key-${apiKeyId ?? ''}.txt`;
        link.click();
    }, [apiKeyId, key]);

    const [copied, setCopied] = React.useState(false);
    const copyKey = React.useCallback(() => {
        if (key == null) {
            return;
        }

        void navigator.clipboard.writeText(key).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 5000);
        });
    }, [key]);

    const [showKey, setShowKey] = React.useState(false);

    if (listApiKeysQuery.isLoading || createApiKeyMutation.isLoading) {
        return <LoadingSpinnerView/>;
    }

    if (listApiKeysQuery.isError) {
        return (
            <EmptySplash
                icon={faExclamationTriangle}
                label='Error loading API keys'
                hint={listApiKeysQuery.error.message}
            />
        );
    }

    if (key != null) {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography.Header>Your new API key</Typography.Header>
                <Typography.SubHeader>
                    This key grants full access to your organization. Keep it private and secure.
                </Typography.SubHeader>
                <div style={{ height: 10 }}/>
                <div style={{ maxWidth: '80%' }}>
                    <div style={{ borderRadius: 5, filter: showKey ? '' : 'blur(5px)', fontFamily: 'monospace', fontSize: 10, background: 'rgba(255, 255, 255, 0.1)', padding: 10, whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                        <code>{key}</code>
                    </div>
                    <div style={{ height: 10 }}/>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                        <Button
                            variant={ButtonVariant.narrow}
                            isSecondary
                            label={showKey ? 'Hide Key' : 'Show Key'}
                            onClick={() => setShowKey(!showKey)}
                            autoWidth
                        />
                        <Button
                            variant={ButtonVariant.narrow}
                            isSecondary
                            label={'Download Key'}
                            onClick={downloadKey}
                            autoWidth
                        />
                        <Button
                            variant={ButtonVariant.narrow}
                            isSecondary
                            label={copied ? 'Copied!' : 'Copy Key'}
                            onClick={copyKey}
                            autoWidth
                        />
                    </div>
                    <div style={{ height: 10 }}/>
                    <div>
                        Your private API key is generated on your computer and is not transmitted to Hiyllo. You will only be able to access this key now. This key should only be used with <InlineLink href='https://www.npmjs.com/package/@hiyllo/api' label='@hiyllo/api'/>, the official Hiyllo API package for NodeJS.
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Button
                variant={ButtonVariant.narrow}
                isSecondary
                label={'Create API Key'}
                onClick={createAPIKey}
                autoWidth
            />
            <div style={{ height: 10 }}/>
            <Table
                header={['API Key ID', 'Created']}
            >
                {listApiKeysQuery.data.apiKeys.map((apiKey) => (
                    <TableRow key={apiKey.apiKeyId}>
                        <TableCell>
                            {apiKey.apiKeyId}
                        </TableCell>
                        <TableCell>
                            {new Date(apiKey.created).toLocaleDateString()}
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </div>
    );
});
