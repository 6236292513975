/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
export type DateYYYYMMDD = string;

export enum PlanKind {
    work_standard = "work_standard",
    support_internal = "support_internal",
    support_basic = "support_basic",
    support_pro = "support_pro",
}

export interface SupportBillingRate {
    type: "support";
    variant: "basic" | "pro";
    
    baseCostE2: number;
    /**
     * The cost charged per user per month
     */
    costPerUser: number;
    /**
     * GB included
     */
    includedGB: number;
    /**
     * Cost per 1GB in cents
     */
    costCentsPerGB: number;
    /**
     * If a custom domain is being used and is not included in plan, cost
     */
    customDomainCostE2: number;
    /**
     * Number of unique visitors included with the plan
     */
    includedVisitorsK: number;
    /**
     * Cost per unique visitor
     */
    costPer1KUniqueVisitorE2: number;
    /**
     * Number of emails included with the plan
     */
    includedEmailsPerMonthBase: number;
    /**
     * Inclueded emails per month per user
     */
    includedEmailsPerMonthPerUser: number;
    /**
     * Cost per 1k additional emails
     */
    costPer1kAddiitionalEmailE2: number;
}

export interface WorkBillingRate {
    type: "work";

    costCentsPerGB: number;
    costPerUser: number;
    includedGBPerUser: number;
}

export type BillingRate = WorkBillingRate | SupportBillingRate;

export interface ServiceRate {
    /**
     * SLA Percentage
     */
    slaUptime: number
    /**
     * SLA Response Time
     */
    slaResponseTime: string
}

export interface SubscriptionFreeTrialType {
    expires: Date;
    maximumSeats: number;
}

export enum SubscriptionStatusEnum {
    active = 'active',
    cancelled = 'cancelled'
}

export interface SubModUserCountAdjustment {
    kind: 'user-count-adjustment';
    adjustment: number;
}

export interface SubModBillingRateAdjustment {
    kind: 'billing-rate-adjustment';
    baseCostE2: number | null;
}

export interface SubscriptionModificationType {
    value: SubModUserCountAdjustment | SubModBillingRateAdjustment;
    createdBy: string;
    note: string;
}

export interface SubscriptionType {
    billingRate: BillingRate,
    resolvedBillingRate: BillingRate,
    // serviceRate: ServiceRate,
    status: SubscriptionStatusEnum,
    /**
     * Date when subscription was initially started
     */
    creationDate: Date;
    /**
     * Day of month to renew, day of month when subscription started or 28, whichever is lower
     */
    dayOfMonth: number;
    /**
     * The number of initial seats to initialize the stack with
     */
    inherentSeats: number;    
    /**
     * Dates that have already been billed, to prevent duplication. Includes initialDate
     */
    billedDates: DateYYYYMMDD[];
    /**
     * @deprecated use `slug` instead
     * UUID of the organization (go here for billing)
     */
    organizationUUID?: string;
    /**
     * Slug of associated federation
     */
    federation: string;
    /**
     * Always use the UUID, never _id
     */    
    uuid: string;
    /**
     * First invoice should be generated on this date (should be midnight EST)
     */
    freeTrial: SubscriptionFreeTrialType | null
    /**
     * Next Payment Date
     */
    nextPaymentDate?: Date | null;

    nextRenewalAmount?: number;

    modifications?: Array<SubscriptionModificationType>;

    productUUID?: string;

    affiliate?: string | null;

    v: 2 | 3;
}